export default {
  namespaced: true,
  state: {
    activeTab: {
      key: "",
      index: 0,
    },

    search: "",

    filters: {
      date: [],
      executive: null,
      university: null,
      eca: null,
      status:'',
      role:''
    },

    payoutForm: {
      item: null,
      show: false,
      type: "",
    },

    executiveModal: {
      id: "",
      show: false,
      type: "",
      university_id: "",
    },

    requestTatModal: {
      item: {},
      type: "",
      show: false,
    },

    ecaStatusHistoryDialog: {
      show: false,
      items: [],
    },
  },
  getters: {
    activeTab: (state) => state.activeTab,
    search: (state) => state.search,
    filters: (state) => state.filters,
    getExecutiveModal: (state) => state.executiveModal,
    getPayoutForm: (state) => state.payoutForm,
    getRequestTatModal: (state) => state.requestTatModal,
    ecaStatusHistoryDialog: (state) => state.ecaStatusHistoryDialog,
  },
  mutations: {
    SET_ACTIVE_TAB(state, tab) {
      const { key, index } = tab;
      state.activeTab.key = key;
      state.activeTab.index = index;
    },

    SET_SEARCH(state, query) {
      state.search = query;
    },

    SET_FILTERS(state, filters) {
      const { date, executive, university, eca, status,role } = filters;

      if ("date" in filters) {
        state.filters.date = date;
      }

      if ("executive" in filters) {
        state.filters.executive = executive;
      }

      if ("university" in filters) {
        state.filters.university = university;
      }

      if ("eca" in filters) {
        state.filters.eca = eca;
      }
      if ("status" in filters) {
        state.filters.status = status;
      }
      if("role" in filters){
        state.filters.role = role
      }
    },

    PAYOUT_FORM(state, obj) {
      if (obj.show) {
        state.payoutForm.item = obj.item ? obj.item : {};
        state.payoutForm.type = obj.type ? obj.type : "";
      }
      state.payoutForm.show = obj.show;
    },
    EXECUTIVE_MODAL(state, obj) {
      state.executiveModal.show = obj.show;
      state.executiveModal.id = obj.id;
      state.executiveModal.type = obj.type;
      if (obj.university_id)
        state.executiveModal.university_id = obj.university_id;
    },

    REQUEST_TAT_MODAL(state, obj) {
      if (obj.show) {
        state.requestTatModal.item = obj.item ? obj.item : {};
        state.requestTatModal.type = obj.type ? obj.type : "";
      }
      state.requestTatModal.show = obj.show;
    },

    TOGGLE_ECA_STATUS_HISTORY_DIALOG(state, obj) {
      state.ecaStatusHistoryDialog = obj;
    },
  },
  actions: {
    setActiveTab({ commit }, obj) {
      commit("SET_ACTIVE_TAB", obj);
    },

    setSearch({ commit }, obj) {
      commit("SET_SEARCH", obj);
    },

    setFilters({ commit }, obj) {
      commit("SET_FILTERS", obj);
    },

    payoutForm({ commit }, obj) {
      commit("PAYOUT_FORM", obj);
    },

    executiveModal({ commit }, obj) {
      commit("EXECUTIVE_MODAL", obj);
    },

    requestTatModal({ commit }, obj) {
      commit("REQUEST_TAT_MODAL", obj);
    },

    toggleEcaStatusHistoryDialog({ commit }, obj) {
      commit("TOGGLE_ECA_STATUS_HISTORY_DIALOG", obj);
    },
  },
};
